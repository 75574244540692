import styled from "styled-components";

export const StyledContainer = styled("div")<any>`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 0px;
  border-top: ${(p) => (p.border ? "1px solid #019f97" : "")};
  

  @media only screen and (max-width: 1024px) {
    max-width: calc(100% - 68px);
    padding: 0 15px;
  }

  @media only screen and (max-width: 768px) {
    max-width: calc(100% - 38px);
    padding: 0 9px;
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
    padding: 0 9px;
  }
`;
